import React from 'react'
import { graphql, navigate } from 'gatsby';
import { Translation } from 'react-i18next';
import { withI18next } from '@wapps/gatsby-plugin-i18next';
import Layout from 'layouts/index'

import {bindActionCreators} from 'redux'
import {connect} from 'react-redux'
import {setDateTime, setRequest, getGroupTypes} from 'store/modules/request';
import {setSchudule, setMarkdown} from 'store/modules/modal';

import analytics from 'utils/analytics'

import _ from 'lodash'

// import FormData from 'formdata-polyfill';

import validate from 'validate.js';

import Calendar from 'macros/Calendar';
import InputText from 'macros/form/InputText';
import InputEmail from 'macros/form/InputEmail';
import InputTextarea from 'macros/form/InputTextarea';
import InputSelect from 'macros/form/InputSelect';
import InputRadio from 'macros/form/InputRadio';
import InputCheckbox from 'macros/form/InputCheckbox';
// import NativeSelect from '@material-ui/core/NativeSelect';
// import InputLabel from '@material-ui/core/InputLabel';


class RequestPage extends React.Component {
    constructor(props) {
        super(props);
        const {groupTypes, isDev} = this.props;

        this.props.setRequest(); // reset request

        this.state = {
            values: {
                group_name: isDev ? 'eee' : '',
                group_type_id: isDev ? '2' : '',
                attendees: isDev ? 10 : '',
                visit_language: isDev ? 'ca' : '',
                observations: isDev ? 'Motivo test' : '',
                gender: isDev ? 'male' : '',
                name: isDev ? 'Lluís' : '',
                surname: isDev ? 'Marfany' : '',
                dni: isDev ? '00000007G' : '',
                email: isDev ? 'lluis@crowd.st' : '',
                phone_number: isDev ? '666 666 666' : '',
                country: isDev ? 'es' : '',
                legal_conditions: isDev ? true : false,
                acceptance_newsletter: isDev ? true : false
            },
            errors: {},
            constrants: {
                group_name: { presence: true},
                group_type_id: { presence: true},
                attendees: { presence: true},
                visit_language: { presence: true},
                observations: { presence: true},
                name: { presence: true},
                surname: { presence: true},
                dni: { presence: true},
                email: { presence: true, email: true},
                phone_number: { presence: true},
                country: { presence: true},
                gender: { presence: true},
                legal_conditions: { presence: true}
            }
        };

        this.getStep = this.getStep.bind(this);

        if(groupTypes === undefined) this.props.getGroupTypes(this.props.pageContext.lng);
    }

    componentDidUpdate(){
        const {actualRequest} = this.props;
        if (actualRequest) {
            analytics({
                "eventName":"bookStep4",
                "dateBook": actualRequest.date.replace(new RegExp('-', 'g'), '|'),
                "hourBook": actualRequest.hour,
                "groupType":"Grupo",
                "assistantsNumber": actualRequest.attendees,
                "language": actualRequest.visit_language
            })
            navigate(`/${this.props.pageContext.lng}/request/success`);
        }
    }

    componentDidMount(){
        analytics({"eventName":"bookStep1"})
    }

    componentWillUnmount() {
        this.props.setDateTime();
    }

    changeInput(ev){
        const{name, value, type, checked} = ev.target;
        const {values} = this.state;
        if(name.indexOf('attendees.') === -1){
            values[name] = type === 'checkbox' ? checked : value;
        }else{
            const position = parseInt(name.split('.')[1]);
            const inputName = name.split('.')[2];
            if(values.attendees === undefined) values.attendees= [];
            if(values.attendees[position] === undefined) values.attendees[position] = {};
            values.attendees[position][inputName] = value;
        }
        this.setState({values});
    }

    getStep() {
        const {dateTime} = this.props;
        return (dateTime === undefined ? 1 : 2);
    }

    handleFormSubmit(ev) {
        ev.preventDefault();
        const {data, dateTime, pageContext} = this.props;
        const {buildingsList: building} = data;
        const {_id:buildingId} = building;
        const {lng} = pageContext;

        const {constrants, values} = this.state;
        const form = ev.target;

        require('formdata-polyfill');
        const formData = new FormData(form);
        const attendees = formData.get('attendees');
        const withPosition = Number(formData.get('group_type_id')) === 4



        if(values['group_type_id'] === 5){
            constrants['group_type_other'] = { presence: true};
        }

        let errors = validate(form, constrants, {fullMessages: false});

        if(withPosition){
            for (let i = 0; i < attendees; i++) {
                errors = this.fieldValidation(`attendees_array.${i}.name`, errors, formData)
                errors = this.fieldValidation(`attendees_array.${i}.company`, errors, formData)
                errors = this.fieldValidation(`attendees_array.${i}.position`, errors, formData)
            }
        }

        if (!errors) {
            formData.append('building_id', buildingId);
            formData.append('web_language', lng);
            formData.append('date', dateTime.format('YYYY-MM-DD'));
            formData.append('hour', dateTime.format('HH:mm'));

            let attendeesArray = [];
            for (let i = 0; i < attendees; i++) {
                let string = 'attendees_array.'+i;

                if(formData.get(string+'.name') || formData.get(string+'.idcard') || formData.get(string+'.position')){
                    let attendee = {
                        name: formData.get(string+'.name')
                    }
                    if(withPosition){
                        attendee.company = formData.get(string+'.company');
                        attendee.position = formData.get(string+'.position');
                    }else{
                        attendee.idcard = formData.get(string+'.idcard');
                    }
                    attendeesArray.push(attendee)
                }
                formData.delete(string+'.name');
                formData.delete(string+'.idcard');
                formData.delete(string+'.company');
                formData.delete(string+'.position');
            }
            formData.append('attendees_array', JSON.stringify(attendeesArray));

            this.props.setRequest(formData);
        } else {
            console.log(form,errors);
            this.showErrors(form, errors || {});
        }
    }

    fieldValidation(field, errors, formData){
        const validation = validate.single(formData.get(field), {presence: true});
        if(validation && validation.length) errors[field] = validation
        return errors;
    }

    showErrors(form, errorsList) {
        let errors = {};
        _.each(form.querySelectorAll("input[name], select[name], textarea[name]"), (input) => {
            errors[input.name] = errorsList[input.name];
        });
        this.setState({errors});
    }

    createAttendees(t){
        const {values, constrants, errors} = this.state;
        const {attendees} = values;

        let attendeesArray = [];
        let withPosition = values.group_type_id === 4;

        const formOptions = {
            t,
            values,
            constrants,
            errors,
            onChange: this.changeInput.bind(this)
        }

        for (let i = 0; i < attendees; i++) {
            attendeesArray.push(<div className="row" key={i}>
                <InputText name={`attendees_array.${i}.name`} label="nameAndSurname" required={withPosition} classes={"col-sm-" + (withPosition ? 4 : 6)} {...formOptions}/>
                <InputText name={`attendees_array.${i}.${withPosition ? 'company' : 'idcard'}`} label={withPosition ? 'company' : 'idcard'} required={withPosition} classes={"col-sm-" + (withPosition ? 4 : 6)} {...formOptions}/>
                {withPosition && <InputText name={`attendees_array.${i}.position`} label="position" required={withPosition} classes={"col-sm-" + (withPosition ? 4 : 6)} {...formOptions}/>}
            </div>)
        }
        return attendeesArray;
    }

    render() {
        const {data, groupTypes, languages, dateTime} = this.props;
        const {groupsList: group, buildingsList: building} = data;

        if(group === undefined) return null;
        if(building === undefined) return null;

        const {_id:buildingId} = building;

        const {values, constrants, errors} = this.state;
        const formOptions = {
            values,
            constrants,
            errors,
            onChange: this.changeInput.bind(this)
        }

        if(groupTypes === undefined) return null;

        return (<Translation>{t => {
            formOptions.t = t;
            const attendeesOptions = _.map(_.fill(Array(16), 0), (p, k) => ({id: k+10, name: (k + 10) + ' ' + t('people')}));
            const genderOptions = [{id: 'male', name: t('mister')}, {id: 'female', name: t('misses')}];
            const languagesOptions = languages.map(l => ({id: l.id, name: t(l.title)}));

            return (<Layout name={'requestIndexPage'} title={t('requestTransact')} {...this.props}>
                <div className="content-title container-fuild">
                    <div className="row">
                        <div className="content-title-block col-sm-7 col-md-8">
                            <h4>{t('requestTransact')}</h4>
                            {this.getStep() === 1 && <h2>{t('requestTransactStep1Title')}</h2>}
                            {this.getStep() === 2 && <h2>{t('requestTransactStep2Title')}</h2>}
                        </div>
                        <div className="content-title-sidebar col-sm-5 col-md-4">
                            <div className="steps">
                                <div className="steps-title">
                                    <h5>{t('steps')}</h5>
                                </div>
                                <ul>
                                    <li className={this.getStep() === 1
                                        ? 'active'
                                        : ''}>1</li>
                                    <li className={this.getStep() === 2
                                        ? 'active'
                                        : ''}>2</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="content-block container-fuild">
                    <div className="row">
                        <div className="content-block-content col-md-8">
                            { this.getStep() === 1 && <Calendar building={buildingId}/>}
                            { this.getStep() === 2 && <div className="background-white container-fluid">
                                <div className="row">
                                    <div className="col-md-10 col-md-offset-1">
                                        <form className="container-fluid" onSubmit={this.handleFormSubmit.bind(this)}>
                                            <h4>{t('requestDetails')}</h4>
                                            <div className="row margin-bottom">
                                                <InputText name="group_name" label="group_name" classes="col-md-6" {...formOptions}/>
                                                <InputSelect name="group_type_id" label="group_type" options={groupTypes} classes="col-md-6" {...formOptions}/>
                                                {values.group_type_id === 5 && <InputText name="group_type_other" label="group_type_other" {...formOptions}/>}
                                                <InputSelect name="attendees" label="attendees" classes="col-md-6" options={attendeesOptions} {...formOptions}/>
                                                <InputSelect name="visit_language" label="visit_language" classes="col-md-6" options={languagesOptions} {...formOptions}/>
                                                <InputTextarea name="observations" label="visitGoal" {...formOptions}/>
                                            </div>
                                            {values.attendees && values.group_type_id !== undefined && [
                                                <h4 key="title">{t('attendees')}</h4>,
                                                <div className="row margin-bottom" key="row">
                                                    <div className="col-md-12">
                                                        {this.createAttendees.bind(this)(t)}
                                                    </div>
                                                </div>
                                            ]}
                                            <h4>{t('requestContactDetails')}</h4>
                                            <div className="row">
                                                <InputRadio name="gender" options={genderOptions} {...formOptions}/>
                                                <InputText name="name" label="name" classes="col-md-6" {...formOptions}/>
                                                <InputText name="surname" label="surname" classes="col-md-6" {...formOptions}/>
                                                <InputText name="dni" label="idcard" classes="col-md-6" {...formOptions}/>
                                                <InputEmail name="email" label="email" classes="col-md-6" {...formOptions}/>
                                                <InputText name="phone_number" label="phone_number" classes="col-md-6" {...formOptions}/>
                                                <InputText name="country" label="country" classes="col-md-6" {...formOptions}/>
                                                <InputCheckbox withLabel={false} name="legal_conditions" label={<span className="underline" onClick={ev =>{this.props.setMarkdown('privacypolicy')}}>{t('legal_conditions') + ' *'}</span>} {...formOptions}/>
                                                <InputCheckbox withLabel={false} name="acceptance_newsletter" label={<span>{t('acceptance_newsletter')}</span>} {...formOptions}/>
                                            </div>
                                            <div className="row margin-bottom">
                                                <div className="col-xs-12 tips">
                                                    <small>{t('mandatory')}</small>
                                                </div>
                                            </div>
                                            <div onClick={()=> {this.props.setDateTime()}} className="btn btn-duo">
                                                {t('back')}
                                            </div>
                                            <button type="submit" className="btn btn-accept margin-left btn-duo" disabled={dateTime === undefined}>
                                                {t('makeRequest')}
                                            </button>
                                        </form>
                                    </div>
                                </div>
                            </div>}
                        </div>
                        <div className="content-block-sidebar col-md-4">
                            <div className="box">
                                {this.getStep() === 1 && <div className="box-content">
                                    <h5>{t('dateStates')}</h5>
                                    <div className="date-selected not-available">{t('dateNotAvailable')}</div>
                                    <div className="date-selected available">{t('dateAvailable')}</div>
                                    <div className="date-selected full">{t('dateFull')}</div>
                                </div>}
                                {this.getStep() === 2  && <div className="box-content">
                                    {dateTime !== undefined && <h4>{t('date')}</h4>}
                                    {dateTime !== undefined && <p>{dateTime.format('LL')}<br/>{dateTime.format('HH:mm')}h</p>}
                                    <p className="text-point">{group.additional_information}</p>
                                    <p className="text-point">{t('requestInformation1')}</p>
                                    <p className="text-point">{t('requestInformation2')}</p>
                                </div>}
                            </div>
                        </div>
                    </div>
                </div>
            </Layout>
        )}}</Translation>);
    }
}

const mapStateToProps = state => ({
    isDev: state.main.isDev,
    dateTime: state.request.dateTime,
    schudule: state.request.schudule,
    actualRequest: state.request.actualRequest,
    languages: state.request.languages,
    groupTypes: state.request.groupTypes
})

export const pageQuery = graphql `
  query($lng: String!){
  groupsList(_id: { eq: 2 },_lang: { eq: $lng }) {
    slug
    additional_information
  },
  buildingsList(_id: { eq: 3 },_lang: { eq: $lng }) {
    _id
    title
    slug
  }
  locales: allLocale(filter: { lng: { eq:$lng }, ns: { eq: "messages" } }) {
    ...LocaleFragment
  }
  site {
    siteMetadata {
      siteTitle
      activeEnv
      analytics
    }
  }
}
`

const mapDispatchToProps = dispatch => bindActionCreators({
    setDateTime,
    setRequest,
    setSchudule,
    getGroupTypes,
    setMarkdown
}, dispatch)

export default connect(mapStateToProps, mapDispatchToProps)(withI18next()(RequestPage))
