import React from "react";
import _ from "lodash"
import {bindActionCreators} from "redux"
import {connect} from "react-redux"

import moment from "moment";
import "moment/locale/es";

import {getDays, getHours} from "./../store/modules/request";

import analytics from 'utils/analytics'

class Calendar extends React.Component {
    constructor(props) {
        super(props);

        this.changeMonth = this.changeMonth.bind(this);
        this.clickDayEvent = this.clickDayEvent.bind(this);
        this.clickDay = this.clickDay.bind(this);

        let delayDays = moment().add(21, 'days');
        let maxDelay = moment().add(180, 'days');

        this.dateFormat = "YYYY-MM-DD";
        this.monthFormat = "MMMM YYYY";

        this.state = {
            building: this.props.building,
            actualDay: delayDays.clone(),
            delayDays,
            maxDelay
        };
    }
    componentDidMount() {
        this.props.getDays(this.state.building, this.state.actualDay.format(this.dateFormat), this.state.maxDelay.format(this.dateFormat));
    }
    clickDayEvent(ev, data) {
        ev.preventDefault();
        if (data.classes.search('active') >= 0) {
            this.clickDay(data.day.format(this.dateFormat));
        }
    }
    clickDay(date) {
        analytics({
            "eventName": "bookStep2",
            "dateBook": date.replace(new RegExp('-', 'g'), '|')
        })
        this.props.getHours(this.state.building, date);
    }
    changeMonth(increment) {
        this.setState({
            actualDay: this.state.actualDay.add(increment, 'M')
        })
    }
    render() {
        const { days, fullDays } = this.props;
        if(days === undefined) return null

        let startDay = this.state.actualDay.clone().startOf('month');
        let endDay = this.state.actualDay.clone().endOf('month').endOf('week');
        let calendar = []
        let daysSelected = Array(7);
        for (var week = startDay.week(); week <= startDay.week() + endDay.clone().endOf('week').diff(startDay, 'weeks'); week++) {
            calendar.push({
                numWeek: week,
                // eslint-disable-next-line
                days: _.map(_.fill(Array(7), 0), (n, i) => {
                    let day = this.state.actualDay.clone().week(week).startOf('week').add(n + i, 'day');
                    let classes = 'calendar-day';

                    if (this.state.actualDay.month() !== day.month()) {
                        classes += ' different-month'
                    } else {
                        if (fullDays.includes(day.format(this.dateFormat))) {
                            classes += ' day-full';
                        }else if (days.includes(day.format(this.dateFormat))) {
                            daysSelected[day.weekday()] = true;
                            classes += ' day-active';
                        }else{
                          classes += ' day-disabled'
                        }
                    }
                    return {day, classes}
                })
            })
        }

        return (
            <div className="calendar">
                <div className="calendar-header">
                    <div className={'calendar-prev' + (startDay <= this.state.delayDays
                        ? ' inactive'
                        : '')} onClick={() => this.changeMonth(-1)}>
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 306 306"><path d="M247.35 270.3L130.05 153l117.3-117.3L211.65 0l-153 153 153 153"/></svg>
                    </div>
                    <h2 className="small calendar-title">{this.state.actualDay.format(this.monthFormat)}</h2>
                    <div className={'calendar-next' + (endDay > this.state.maxDelay.endOf('week')
                        ? ' inactive'
                        : '')} onClick={() => this.changeMonth(1)}>
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 306 306"><path d="M247.35 270.3L130.05 153l117.3-117.3L211.65 0l-153 153 153 153"/></svg>
                    </div>
                </div>
                <div className="calendar-body">
                    <div className="calendar-th">
                        <div className="calendar-th-inner">
                            {moment.weekdaysMin(true).map(function(daytitle, dt) {
                                return <div key={dt} className={'calendar-tday' + (daysSelected[dt]
                                    ? ' active'
                                    : '')}>{daytitle}</div>
                            })}
                        </div>
                    </div>
                    <div className="calendar-days">
                        {calendar.map(function(week, i) {
                            return <div className="calendar-week" key={i}>
                                {week.days.map(function(data, d) {
                                    return <div className={data.classes} key={d} onClick={ev => this.clickDayEvent(ev, data)}>{data.day.date()}</div>
                                }, this)}
                            </div>;
                        }, this)}
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = state => ({days: state.request.days, fullDays: state.request.fullDays})

const mapDispatchToProps = dispatch => bindActionCreators({
    getDays,
    getHours
}, dispatch)

export default connect(mapStateToProps, mapDispatchToProps)(Calendar)
