import React from "react";
import InputText from "./InputText";
import MenuItem from '@material-ui/core/MenuItem';

export default props => props.options ? (
    <InputText select {...props}>
        {
            props.options.map(option => (
                !props.native ? 
                <MenuItem key={option.id} value={option.id}>
                    {option.name}
                </MenuItem>
                :
                <option key={option.id} value={option.id}>{option.name}</option>
                )
            )
        }
    </InputText>
) : null;
