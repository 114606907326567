import React from "react";
import PropTypes from 'prop-types';

import Checkbox from '@material-ui/core/Checkbox';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormHelperText from '@material-ui/core/FormHelperText';

class InputCheckbox extends React.Component {
    render(){
        const {name, classes, label, values, onChange, errors} = this.props;

        if(name === undefined) return null;
        if(values === undefined) return null;
        if(onChange === undefined) return null;

        return (<div className={'form-group col-xs-12' + (classes ? ' '+classes : '') + (errors[name] !== undefined ? ' has-error' : '')}>
            <FormControl error={errors[name] !== undefined} component="fieldset" style={{display:'inline'}}>
                <FormControlLabel
                  control={
                      <Checkbox
                          name={name}
                          checked={values[name] === true}
                          onChange={onChange}
                          value="1"
                          color="primary"
                      />
                  }
                  label={label}
                />
                {errors[name] && <FormHelperText>{errors[name]}</FormHelperText>}
            </FormControl>
        </div>)
    }
}

InputCheckbox.defaultProps = {
    classes: ''
};

InputCheckbox.propTypes = {
    name: PropTypes.string.isRequired,
    classes: PropTypes.string,
    values: PropTypes.object.isRequired,
    onChange: PropTypes.func.isRequired
};

export default InputCheckbox;
