import React from "react";
import PropTypes from 'prop-types';

import Radio from '@material-ui/core/Radio';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormHelperText from '@material-ui/core/FormHelperText';

class InputRadio extends React.Component {
    render(){
        const {name, classes, options, values, onChange, errors} = this.props;

        if(name === undefined) return null;
        if(values === undefined) return null;
        if(onChange === undefined) return null;
        if(options === undefined) return null;
        
        return (
            <div className={'form-group col-xs-12' + (classes ? ' '+classes : '') + (errors[name] !== undefined ? ' has-error' : '')}>
                <FormControl error={errors[name] !== undefined} component="fieldset" style={{display:'inline'}}>
                    {options.map(o => <FormControlLabel key={o.id} value="female" control={<Radio
                          checked={values[name] === o.id}
                          onChange={onChange}
                          value={o.id}
                          name={name}
                          aria-label={o.id}
                          color="primary"
                        />} label={o.name} />)}
                    {errors[name] && <FormHelperText>{errors[name]}</FormHelperText>}
                </FormControl>
            </div>
        )
    }
}

InputRadio.defaultProps = {
    classes: ''
};

InputRadio.propTypes = {
    name: PropTypes.string.isRequired,
    classes: PropTypes.string,
    options: PropTypes.array.isRequired,
    values: PropTypes.object.isRequired,
    onChange: PropTypes.func.isRequired
};

export default InputRadio;
